import { optional, withDependencies } from '@wix/thunderbolt-ioc'
import { ITpaHandlersManager } from './types'
import { TpaHandlersManagerSymbol } from './symbols'
import { WindowMessageRegistrarSymbol, IWindowMessageRegistrar } from 'feature-window-message-registrar'
import { TbDebugSymbol, DebugApis } from 'feature-debug'
import { editorOnlyHandlers, isTpaMessage, parseMessage } from './tpaMessageUtilis'

export const TpaMessageContextPickerDs = withDependencies(
	[WindowMessageRegistrarSymbol, TpaHandlersManagerSymbol, optional(TbDebugSymbol)],
	(
		windowMessageRegistrar: IWindowMessageRegistrar,
		tpaHandlersManager: ITpaHandlersManager,
		debugApi?: DebugApis
	) => {
		// TODO mimicking lifecycle hooks order until proper implementation option is availabe (i.e component lifecycle hooks)
		setTimeout(() => {
			windowMessageRegistrar.addWindowMessageHandler({
				canHandleEvent(event: MessageEventInit) {
					return !!(event.source && isTpaMessage(parseMessage(event)))
				},
				async handleEvent(event: MessageEventInit) {
					const message = parseMessage(event)
					const { type, compId } = message

					if (editorOnlyHandlers.includes(type)) {
						return
					}

					if (debugApi) {
						debugApi.tpa.addMessage({ message, compId, contextId: 'editor' })
					}

					tpaHandlersManager.handleMessage(event.source as any, message).catch((e) => {
						console.error('HandleTpaMessageError', type, 'editor', compId, e)
					})
				},
			})
		}, 1000)

		return {}
	}
)
